import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import AboutSvg from "./AboutSvg";

export default function About({ reference }) {
    return (
        <Container fluid style={{ backgroundColor: "#0F1221" }} className="pt-5" ref={reference}>
            <Row>
                <Col sm={6} md={6} className="d-flex flex-column-reverse">
                    <AboutSvg />
                </Col>
                <Col sm={6} md={6}>
                    <div
                        id="w-node-_1433fc88-3b99-d167-4a53-77a6a7972dbe-52788670"
                        data-w-id="1433fc88-3b99-d167-4a53-77a6a7972dbe"
                        /* style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;" */ class="overview-text-wrap"
                    >
                        <div class="subheading-large _16-bottom-margin">WHO IS SAIDEV LAKKAKULA&nbsp;</div>
                        <div class="wrap-horizontal">
                            <div class="freelancer-icon">
                                <img src="https://assets.website-files.com/6035e7f3f25cec45fe78866f/6035e7f3f25cec42a0788694_Alien%20SVG.svg" loading="lazy" alt="Nothing found" class="alien-svg" />
                            </div>
                            <h2>A bit about me</h2>
                        </div>
                        <p class="paragraph-grey-300 top-bottom-margin">
                            Former Mechanical Engineer and currently a certified MERN Stack Developer, currently working as Frontend Developer @PxMedia.de GmbH, Rostock. I strive to develop and bulid
                            websites which includes onepage applications using modern development tools whilst handling development both on client and server side, alongside updating myself with the
                            latest technologies.
                            <br />
                            <br />
                            You are most welcome to get in touch for any suggestions or collaborations.
                        </p>
                        <a href="/contact" class="button-grey w-button">
                            Start a Project
                        </a>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
