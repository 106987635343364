import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import "aos/dist/aos.css";
import Aos from "aos";
import React, { useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import Navbar from "./components/Navbar";
import Intro from "./components/Intro";
import Showcase from "./components/Showcase";
import About from "./components/About";
import Technologies from "./components/Technologies";
import Projects from "./components/Projects";
import Footer from "./components/Footer";

function App() {
  const navbar = useRef();
  const about = useRef();
  const works = useRef();
  const services = useRef();
  const contact = useRef();

  useEffect(() => {
    scrollToDiv(navbar);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 800, once: true });
  }, []);

  const scrollToDiv = (ref) => {
    window.scrollTo({
      top: (0, ref.current.offsetTop),
      behavior: "smooth"
    });
  };

  return (
    <div>
      <Navbar about={about} works={works} services={services} contact={contact} click={(element) => scrollToDiv(element)} reference={navbar} />
      <div className="body-div">
        <Container fluid className=" mt-4">
          <Intro />
          <Showcase />
        </Container>
      </div>
      <About reference={about} />

      <Container fluid className="my-4">
        <Technologies reference={services} />
        <Projects reference={works} />
      </Container>

      <Footer reference={contact} />
    </div>
  );
}

export default App;
