import React from "react";

export default function Showcase() {
    return (
        <div>
            <div class="feature-section">
                <div class="container-large">
                    <div data-w-id="Grid" class="w-layout-grid numbers-grid">
                        <div class="grid-cell" data-aos="fade-up">
                            <h2 class="feature-text mb-2">5+</h2>
                            <div class="subheading-large grey-400">Projects completed</div>
                        </div>

                        <div class="grid-cell" data-aos="fade-up">
                            <h2 class="feature-text mb-2">1.5</h2>
                            <div class="subheading-large grey-400">year experience</div>
                        </div>
                        <div class="grid-cell" data-aos="fade-up">
                            <h2 class="h2-title mb-2">3+</h2>
                            <div class="subheading-large grey-400">happy clients</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
