import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import ProfessionalPhoto from "../images/profilephoto.png";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

export default function Footer({ reference }) {
    return (
        <Container fluid style={{ backgroundColor: "#0F1221", color: "white" }} className="p-5" ref={reference}>
            <Row className="d-flex justify-content-center my-5" data-aos="fade-up">
                <Col md={3} sm={3} lg={3}>
                    <div className="mb-5">
                        <img src={ProfessionalPhoto} loading="lazy" alt="Nothing found" className="avatar-logo-contact" />
                    </div>
                    <h5 className="h5-white">Saidev Lakkakula</h5>
                    <div className="titleDeveloper greyFont">FullStack Developer</div>
                    <div className="email greyFont">Email me at</div>
                    <a href="mailto:lakkakula.saidev@gmail.com?subject=Hello" className="paragraph-large bold-white">
                        lakkakula.saidev@gmail.com
                    </a>
                </Col>
                <Col md={3} sm={3} lg={3} className="d-flex flex-column">
                    <h5 className="display-heading mb-5">Lets Talk!</h5>
                    <div className="d-flex flex-column flex-grow-1 justify-content-between">
                        <div className="greyFont">I am always open to discuss your project, improve your online presence or help with your UX/UI design challenges.</div>
                        <div className="d-flex flex-row pb-1">
                            <a href="https://linkedin.com/in/saidev-lakkakula/" className="px-2" target="_blank" rel="noopener noreferrer">
                                <LinkedInIcon style={{ color: "white", fontSize: "28px" }} />
                            </a>
                            <a href="https://github.com/lakkakula-saidev" className="px-2" target="_blank" rel="noopener noreferrer">
                                <GitHubIcon style={{ color: "white" }} />
                            </a>
                            <a href="mailto:lakkakula.saidev@gmail.com?subject=Hello" className="px-2">
                                <MailOutlineIcon style={{ color: "white", fontSize: "28px" }} />
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
