import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Typewriter from "typewriter-effect";
import emailjs from "emailjs-com";

export default function Intro() {
    const sendEmail = (e) => {
        e.preventDefault();

        if (e.target.email.value.length > 5) {
            emailjs.sendForm("service_ql7x7ir", "template_orgyw7q", e.target, "user_J0yvucLt6S8XXewOStSu3").then(
                (result) => {
                    console.log(result.text, "Email sent sucessfully");
                    alert("Connection request sent sucessfully...");
                },
                (error) => {
                    console.log(error.text, "Error while sending connection request...");
                    alert("Error while sending connection request...");
                }
            );
        } else {
            alert("Please enter a valid email");
        }
        e.target.reset();
    };

    return (
        <Container fluid className="py-4 w-100 px-5 bg-image bg-light IntroContainer">
            <Row className="py-4 no-gutters">
                <Col xs={12} sm={12} md={12} lg={6} className="py-4">
                    <div className="aboutTitle font-weight-bold">Buliding digital products, Brands...</div>
                    <div className="aboutDetail my-4">
                        <p>
                            <span class="font-weight-bold">
                                <Typewriter
                                    options={{ autoStart: true, loop: true }}
                                    onInit={(typewriter) => {
                                        typewriter.typeString("Full Stack Developer...").pauseFor(2000).deleteAll().typeString("MERN Stack Developer..").pauseFor(10000).start();
                                    }}
                                />
                            </span>
                            based in Germany. Specialized in Frontend, Responsive Web Design and real time Chat Applications
                        </p>
                    </div>
                    <div class="email-form-block w-form">
                        <form id="email-form" name="email-form" data-name="Email Form" class="email-form custom-form-class" onSubmit={sendEmail}>
                            <input type="email" class="text-field w-input mt-4" maxlength="256" name="email" data-name="Email 2" placeholder="Email address" id="email-2" required="" />
                            <input type="submit" value="Connect With Me" data-wait="Please wait..." data-w-id="60911ee2-f7b7-2852-bace-731a6f840bd0" class="hero-button w-button mt-4" />
                        </form>
                        <div class="success-message w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div class="error-message w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
