import React from "react";
import { Container } from "react-bootstrap";
import reactLogo from "../images/react.png";
import reduxLogo from "../images/redux.png";
import mongoDB from "../images/mongoDB.png";
import jsLogo from "../images/js.png";
import socketIcon from "../images/socketio.png";

export default function Technologies({ reference }) {
    return (
        <Container fluid ref={reference}>
            <div className="content-section">
                <div>
                    <h3>Technologies</h3>
                </div>
                <div className="d-flex flex-row justify-content-center flex-wrap py-4">
                    <div data-aos="fade-up" className="p-4 m-2 d-flex justify-content-between align-items-center" style={{ height: "100px" }}>
                        <img src={reactLogo} alt="" width="100%" height="100%" />
                        <div className="px-2">
                            <h4>React</h4>
                        </div>
                    </div>
                    <div data-aos="fade-up" className="p-4 m-2 d-flex justify-content-between align-items-center" style={{ height: "100px" }}>
                        <img src={reduxLogo} alt="" width="100%" height="100%" />
                        <div className="px-2">
                            <h4>Redux</h4>
                        </div>
                    </div>
                    <div data-aos="fade-up" className="p-4 m-2 d-flex justify-content-between align-items-center" style={{ height: "100px" }}>
                        <div className="px-2">
                            <h4>Express</h4>
                        </div>
                        <img src={jsLogo} alt="" width="100%" height="100%" />
                    </div>
                    <div data-aos="fade-up" className="p-4 m-2 d-flex justify-content-between align-items-center" style={{ height: "100px" }}>
                        <img src={mongoDB} alt="" width="100%" height="100%" />
                        <div className="px-1">
                            <h4>mongoDB</h4>
                        </div>
                    </div>
                    <div data-aos="fade-up" className="p-4 m-2 d-flex justify-content-between align-items-center" style={{ height: "100px" }}>
                        <img src={socketIcon} alt="" width="100%" height="100%" />
                        <div className="px-1">
                            <h4>Socket.IO</h4>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}
