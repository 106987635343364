import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import ProfessionalPhoto from "../images/profilephoto.png";

export default function NavBar({ about, works, services, contact, click, reference }) {
    return (
        <div className="nav-main-div" ref={reference}>
            <Navbar collapseOnSelect fixed="top" expand="sm" bg="white" className="navbar py-4">
                <Container className="mx-5 px-5">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Navbar.Brand href="#home">
                            <img alt="" src={ProfessionalPhoto} className="avatar-logo-nav" />
                        </Navbar.Brand>
                        <Nav className="me-auto">
                            <Nav.Link className="pr-4" ref={about} onClick={() => click(about)}>
                                About
                            </Nav.Link>
                            <Nav.Link className="pr-4" ref={works} onClick={() => click(works)}>
                                Works
                            </Nav.Link>
                            <Nav.Link className="pr-4" ref={services} onClick={() => click(services)}>
                                Services
                            </Nav.Link>
                            <Nav.Link className="pr-4 mr-4" ref={contact} onClick={() => click(contact)}>
                                Contact
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}
