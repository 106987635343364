import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import TravCompanion from "../images/TravCompanion.png";
import LinkedIn from "../images/LinkedIn.png";
import Spotify from "../images/Spotify.png";

export default function Projects({ reference }) {
    return (
        <Container fluid className="w-100" ref={reference}>
            <Row className="no-gutters projectsRow">
                <Col xs={12} sm={12} md={12} className="py-2">
                    <h3>Latest Projects</h3>
                    <div className="projects-List d-flex flex-column">
                        <div className="project-Item d-flex flex-column">
                            <a href="https://travel-companion-murex.vercel.app" target="_blank" rel="noopener noreferrer">
                                <div className="mb-4 img-wrapper">
                                    <img
                                        alt="Nothing found"
                                        src={TravCompanion}
                                        className="Project-image" /* style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;" */
                                    />
                                </div>
                                <div className="d-flex flex-row justify-content-between align-items-center mb-5 ">
                                    <h4 style={{ color: "black" }}>TravCompanion</h4>
                                    <div className="project-detail">September 22, 2021</div>
                                </div>
                            </a>
                        </div>
                        <div className="project-Item d-flex flex-column my-5">
                            <a href="https://bw3-linkedin-clone.vercel.app" target="_blank" rel="noopener noreferrer">
                                <div className="mb-4 img-wrapper">
                                    <img
                                        alt="Nothing found"
                                        src={LinkedIn}
                                        className="Project-image" /* style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;" */
                                    />
                                </div>
                                <div className="d-flex flex-row justify-content-between align-items-center ">
                                    <h4 style={{ color: "black" }}>Chat Application</h4>
                                    <div class="project-detail">July 24, 2021</div>
                                </div>
                            </a>
                        </div>
                        <div className="project-Item d-flex flex-column my-5">
                            <a href="https://spotify-react-2.vercel.app" target="_blank" rel="noopener noreferrer">
                                <div className="mb-4 img-wrapper">
                                    <img
                                        alt="Nothing found"
                                        src={Spotify}
                                        className="Project-image" /* style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;" */
                                    />
                                </div>
                                <div className="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                    <h4 style={{ color: "black" }}>Spotify Clone</h4>
                                    <div class="project-detail">June 24, 2021</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
